<template>
  <div v-if="ifOnload" class='userInfo userInfoHim5' style="overflow: hidden;">
    <h6>请填写以下信息</h6>
    <van-form @submit="seeFrom" v-if="onSeeform">
      <div v-for="(item, index) in userFormData" :key="index">
        <!-- 编号 -->
        <van-field v-if="item.type === 'DataValue'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" input-align="right" />
        <!-- 姓名 -->
        <van-field v-else-if="item.type === 'UserName'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" input-align="right" right-icon="arrow"
          @click="handlerInfos(item.type)" />
        <!-- 身高-->
        <van-field v-else-if="item.type === 'Height'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" input-align="right"
          @click="handlerInfos(item.type)">
          <span slot="button">(单位:cm)</span>
        </van-field>
        <!-- 体重-->
        <van-field v-else-if="item.type === 'Weight'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" input-align="right"
          @click="handlerInfos(item.type)">
          <span slot="button">(单位:<span style="color:red">公斤</span>)</span>
        </van-field>
        <!-- 出生地选择 -->
        <van-field v-else-if="item.type === 'region'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" input-align="right" right-icon="arrow"
          @click="showArea = true" />
        <!-- 性别选择 -->
        <van-field v-else-if="item.type === 'Sex'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" input-align="right" right-icon="arrow"
          @click="showPickerSex = true" />
        <!-- 现居地选择 -->
        <van-field v-else-if="item.type === 'NowPlace'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" input-align="right" right-icon="arrow"
          @click="showResidence = true" />
        <!-- 是否切除器官 -->
        <van-field v-else-if="item.type === 'SlicingOrgan'" :type="item.type" v-model="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" input-align="right" right-icon="arrow"
          :columns="organList" @click="handleQg(item.type)" />
        <!-- 最后一次月经时间 -->
        <van-field v-else-if="item.name === 'BeforeMenseTime'" readonly clickable :name="item.name" :value="item.value"
          :label="item.label" :placeholder="item.placeholder" input-align="right" right-icon="arrow" label-width="8em"
          @click="showYjPicker = true" />
        <!-- 选择出生年月 -->
        <van-field v-else-if="item.type === 'time'" readonly clickable :name="item.name" :value="item.value"
          :label="item.label" :placeholder="item.placeholder" input-align="right" right-icon="arrow"
          @click="showPicker = true" />

        <!-- 性别选择 -->
        <van-popup v-model="showPickerSex" position="bottom">
          <van-picker show-toolbar :columns="columns" @confirm="onConfirmSex" @cancel="showPickerSex = false" />
        </van-popup>
        <!-- 出生地选择 -->
        <van-popup v-model="showArea" position="bottom">
          <van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
        </van-popup>
        <!-- 现居地选择 -->
        <van-popup v-model="showResidence" position="bottom">
          <van-area :area-list="areaList" @confirm="onConfirmResidence" @cancel="showResidence = false" />
        </van-popup>
        <!-- 出生时间选择器 -->
        <van-popup v-model="showPicker" position="bottom">
          <van-datetime-picker v-model="currentDate" type="date" :columns-order="['year', 'month', 'day']"
            :max-date="maxDate" :min-date="minDate" @confirm="onConfirmTime" @cancel="showPicker = false" />
        </van-popup>

        <!-- 月经时间时间选择器 -->
        <van-popup v-model="showYjPicker" position="bottom">
          <van-datetime-picker v-model="currentYjDate" type="date" :columns-order="['year', 'month', 'day','hour']"
            :max-date="maxDate" :min-date="minDate" @confirm="onConfirmYjTime" @cancel="showYjPicker = false" />
        </van-popup>
        <!--弹窗 -->
        <van-dialog v-model="showDialog" :title="dialogTitName" @confirm="handSureDialog" @cancel="cancelDialog"
          confirmButtonColor="#1aad19" show-cancel-button>
          <ul class="unmit" v-if="onShowIng">
           <li v-for="(tag, index) in qgList" :key="index">
              <van-checkbox-group  v-model="checkList">
                <van-checkbox @click='checkboxChange' :name="tag.TitleName" checked-color="#1aad19">{{tag.TitleName}}</van-checkbox>
              </van-checkbox-group>
            </li>
          </ul>
          <div style="margin: 10px 20px; padding: 0 10px;" v-else>
            <van-cell-group :border="true">
              <van-field v-model="textVal" :placeholder="placeholderName" :border="true" />
            </van-cell-group>
          </div>
        </van-dialog>
      </div>
    </van-form>
    <!-- 回显数据 -->
    <van-form @submit="seeFrom" v-if='!onSeeform'>
      <div v-for="(item, index) in userFormData" :key="index">
        <!-- 编号 -->
        <van-field v-if="item.type === 'DataValue'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" input-align="right" />
        <!-- 姓名 -->
        <van-field v-else-if="item.type === 'UserName'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" input-align="right" />
        <!-- 身高-->
        <van-field type="number" v-else-if="item.type === 'Height'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" input-align="right">
          <span slot="button">(单位:cm)</span>
        </van-field>
        <!-- 体重-->
        <van-field  type="number" v-else-if="item.type === 'Weight'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" input-align="right">
          <span slot="button">(单位:<span style="color:red">公斤</span>)</span>
        </van-field>
        <!-- 出生地选择 -->
        <van-field v-else-if="item.type === 'region'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" input-align="right" />
        <!-- 性别选择 -->
        <van-field v-else-if="item.type === 'Sex'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" input-align="right" />
        <!-- 现居地选择 -->
        <van-field v-else-if="item.type === 'NowPlace'" readonly clickable :value="item.value" :name="item.name"
          :label="item.label" :placeholder="item.placeholder" :rules="item.rules" input-align="right" />
        <!-- 是否切除器官 -->
        <van-field v-else-if="item.type === 'SlicingOrgan'" readonly clickable :type="item.type" v-model="item.value"
          :name="item.name" :label="item.label" :placeholder="item.placeholder" :rules="item.rules"
          input-align="right" />
        <!-- 最后一次月经时间 -->
        <van-field v-else-if="item.name === 'BeforeMenseTime'" readonly clickable :name="item.name" :value="item.value"
          :label="item.label" :placeholder="item.placeholder" input-align="right" label-width="5.2em" />
        <!-- 选择出生年月 -->
        <van-field v-else-if="item.type === 'time'" readonly clickable :name="item.name" :value="item.value"
          :label="item.label" :placeholder="item.placeholder" input-align="right" />
      </div>
    </van-form>

    <div class="submitBtn">
      <div v-if="onSeeform" class="seebtns">
        <van-button block size="small" style="width:100px" type="primary" native-type="submit" @click="seeFrom">预览
        </van-button>
      </div>
      <div v-if="!onSeeform&&!ifYl" class="seeSumb">
        <van-button size="small" style="width:100px" block plain hairline type="primary" @click="cancelFrom">取消
        </van-button>
        <van-button size="small" style="width:100px" block type="primary" native-type="submit" @click="onSubmit">提交
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GetInsertReportUserA,
  GetDataValueIsExist,
  GetReportOrganBySex
} from '@/api/index'
import {
  areaList
} from '@vant/area-data'
import {
  mapGetters
} from 'vuex'
import wx from '@/util/jweixin1.3.2'
export default {
  components: {

  },
  computed: {
    ...mapGetters([
      'userid'
    ])
  },
  data () {
    return {
      ifOnload: false,
      userFormData: [{
        name: 'DataValue',
        label: '编号',
        placeholder: '编号',
        type: 'DataValue',
        rules: [{
          required: false,
          message: '请填写编号'
        }],
        value: '',
        disabled: false
      }, {
        name: 'UserName',
        label: '姓名',
        placeholder: '请填写',
        rules: [{
          required: false,
          message: '请填写姓名'
        }],
        type: 'UserName',
        disabled: false,
        value: ''
      }, {
        name: 'Sex',
        label: '性别',
        placeholder: '请选择',
        rules: [{
          required: false,
          message: '请选择身份证上性别'
        }],
        type: 'Sex',
        disabled: false,
        value: ''
      }, {
        name: 'Height',
        label: '身高',
        placeholder: '请输入',
        type: 'Height',
        rules: [{
          required: false,
          message: '请输入身高'
        }],
        disabled: false,
        value: ''
      }, {
        name: 'Weight',
        label: '体重',
        placeholder: '请输入',
        type: 'Weight',
        rules: [{
          required: false,
          message: '请输入体重'
        }],
        disabled: false,
        value: ''
      }, {
        name: 'Birthday',
        label: '出生年月',
        placeholder: '请选择',
        type: 'time',
        rules: [{
          required: false,
          message: '请选择出生年月'
        }],
        disabled: false,
        value: ''
      }, {
        name: 'birthdayPlace',
        label: '出生地',
        placeholder: '请选择',
        rules: [{
          required: false,
          message: '请输入出生地'
        }],
        disabled: false,
        type: 'region',
        value: ''
      }, {
        name: 'NowPlace',
        label: '现居地',
        placeholder: '请选择',
        rules: [{
          required: false,
          message: '请选择现居地'
        }],
        disabled: false,
        type: 'NowPlace',
        value: ''
      }, {
        name: 'SlicingOrgan',
        label: '是否切除器官',
        placeholder: '请选择',
        rules: [{
          required: false,
          message: '请选择是否切除器官'
        }],
        type: 'SlicingOrgan',
        disabled: false,
        value: ''
      }, {
        name: 'BeforeMenseTime',
        label: '最近一次月经时间',
        placeholder: '请选择最近一次月经时间',
        rules: [{
          required: true,
          message: '请填写最近一次月经时间'
        }],
        type: 'time',
        value: ''
      }],
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      defaultDate:new Date(1997, 1, 1),
      areaList, // 地址选择数据
      form: {},
      showArea: false,
      showResidence: false,
      showPicker: false,
      showYjPicker: false,
      currentDate: new Date(1997, 0, 1),
      currentYjDate: new Date(),
      showPickerSex: false,
      columns: ['男', '女'],
      organList: [],
      showDialog: false,
      checkbox: false,
      checkboxGroup: [],
      qgList: [],
      textVal: '',
      dialogTitName: '',
      placeholderName: '请输入',
      txtVal: '',
      onShowIng: false,
      checkList: [],
      checkObj: [],
      onSeeform: true,
      oldDate: '',
      formA: '',
      ifYl: false,
      code:''
    }
  },
  props: {

  },
  created () {
    const openid = this.$query('openid')
    const token = this.$query('token')
    const userid = this.$query('userid')
    this.code = this.$query('code')
    console.log(this.code)
  },

  mounted () {
    
    const geturl = window.location.href
    const getqyinfo = geturl.split('?')[1]
    const getqys = new URLSearchParams('?' + getqyinfo)
    try {
      this.form.UserCode = getqys.get('UserCode')
      this.form.WebsiteOwner = getqys.get('WebsiteOwner')
      this.form.DataValue = getqys.get('DataValue')
      this.$toast.fail(this.form.UserCode)
    } catch (e) {
      // TODO handle the exception
    }
    console.log('this.form', this.form)
    if (this.$router.history.current.query.DataValue) {
      const residence = this.userFormData.find(el => el.type === 'DataValue')
      residence.value = getqys.get('DataValue')
    }

    this.getUserInfo()
  },
  methods: {
    checkboxChange (e) {
      console.log('选项改变', this.checkList)
      if (this.checkList.indexOf('无') == -1) {

      } else if (this.checkList.indexOf('无') == this.checkList.length - 1) {
        this.checkList = ['无']
      } else if (this.checkList.indexOf('无') == 0) {
        // console.log('this.checkList.splice(0, 1)',this.checkList.shift())
        this.checkList.shift()
        console.log('this.checkList', this.checkList)
      }
    },
    // 获取器官信息
    getQgInfos (sex) {
      this.$toast.loading({
        message: '获取信息中'
      })
      GetReportOrganBySex({
        Sex: sex
      }).then(res => {
        console.log('获取器官信息', res)
        if (res.code == 0) {
          res.data.map(tag => {
            tag.checked = false
          })
          this.qgList = res.data
        } else {
          this.$toast.fail('信息获取失败')
        }
        this.$toast.clear()
      })
    },
    // 获取用户信息
    getUserInfo () {
      this.$toast.loading({
        message: '获取信息中'
      })

      // GetDataValueIsExist({ websiteOwner:'eees', DataValue: 'eewe-111' }).then(res => {
      const data = {
        websiteOwner: this.form.WebsiteOwner,
        DataValue: this.form.DataValue
      }
      console.log('data111', data)
      GetDataValueIsExist(data).then(res => {
        if (res.code == 0) {
          console.log('res.msg==', res.msg == '用户已存在')
          if (res.msg == '用户已存在') {
            const data = res.data
            this.userFormData.map(item => {
              console.log(item, '123132')
              if (item.name == 'UserName') {
                item.value = data.UserName
              } else if (item.name == 'Sex') {
                item.value = data.Gender
              } else if (item.name == 'DataValue') {
                item.value = data.DataValue
              } else if (item.name == 'Height') {
                item.value = data.Height
              } else if (item.name == 'Weight') {
                item.value = data.Weight
              } else if (item.name == 'Birthday') {
                item.value = data.Birthday
              } else if (item.name == 'birthdayPlace') {
                item.value = data.BirthdayPlace
              } else if (item.name == 'NowPlace') {
                item.value = data.NowPlace
              } else if (item.name == 'SlicingOrgan') {
                item.value = data.SlicingOrgan
              } else if (item.name == 'BeforeMenseTime') {
                item.value = data.Mense
              }
            })
            this.onSeeform = false
            this.ifYl = true
            const residence = this.userFormData.find(el => el.type === 'Sex')
            if (residence.value == '男') {
              this.userFormData.pop()
            }
            console.log('residence.value', residence.value)
            this.form = res.data
          } else {
            this.userFormData.pop()
          }

          // 判断如何没有信息调取
          // 获取器官列表
        } else {
          this.$toast.fail('信息获取失败')
        }
        this.$toast.clear()
        this.ifOnload = true
      })
    },

    handlerInfos (txt) {
      this.showDialog = true
      console.log('txt', txt)
      this.txtVal = txt
      if (txt == 'UserName') {
        this.dialogTitName = '姓名'
        this.placeholderName = '请输入姓名'
      } else if (txt == 'Height') {
        this.dialogTitName = '身高'
        this.placeholderName = '请输入身高'
      } else if (txt == 'Weight') {
        this.dialogTitName = '体重'
        this.placeholderName = '请输入体重'
      }
      this.textVal = ''
    },

    cancelDialog () {
      const that = this
      this.showDialog = false
      this.onShowIng = false
      console.log('this.dialogTitName', this.dialogTitName, this.dialogTitName == '是否切除器官')
      if (this.dialogTitName == '是否切除器官') {
        this.onShowIng = true
      } else {
        this.onShowIng = false
      }
      setTimeout(function () {
        that.onShowIng = false
        that.$forceUpdate()
      }, 300)
    },
    handSureDialog (txt) {
      const that = this
      this.showDialog = false
      this.onShowIng = false
      if (this.dialogTitName == '是否切除器官') {
        this.onShowIng = true
      } else {
        this.onShowIng = false
      }
      setTimeout(function () {
        that.onShowIng = false
        that.$forceUpdate()
      }, 300)
      var reg = new RegExp('^[0-9]*$')
      if (this.txtVal == 'UserName' && reg.test(this.textVal)) {
        this.$toast.fail('格式错误')
      } else if (this.txtVal == 'SlicingOrgan') {
        this.userFormData[8].value = String(this.checkList)
      } else {
        this.userFormData.forEach((item, idx) => {
          if (item.name == this.txtVal) {
            this.userFormData[idx].value = this.textVal
          }
        })
      }
    },
    handleQg (txt) {
      this.dialogTitName = '是否切除器官'
      this.showDialog = true
      this.onShowIng = true
      this.txtVal = txt
    },
    // 出生地选择
    onConfirm (values) {
      const birthplace = this.userFormData.find(el => el.type === 'region')
      birthplace.value = `${values[0].name}-${values[1].name}-${values[2].name}`

      this.form.provice = values[0].name
      this.form.city = values[1].name
      this.form.area = values[2].name
      this.form.birthplace = birthplace.value
      this.showArea = false
    },
    // 现居地选择
    onConfirmResidence (values) {
      const residence = this.userFormData.find(el => el.type === 'NowPlace')
      residence.value = `${values[0].name}-${values[1].name}-${values[2].name}`
      this.form.residence = residence.value
      this.showResidence = false
    },
    // 性别选择
    onConfirmSex (values) {
      const residence = this.userFormData.find(el => el.type === 'Sex')
      residence.value = values === '男' ? '男' : '女'
      let ifBeforeMenseTime = false
      for (let i = 0; i < this.userFormData.length; i++) {
        if (this.userFormData[i].name == 'BeforeMenseTime') {
          ifBeforeMenseTime = true
        } else {
          ifBeforeMenseTime = false
        }
      }
      if (values == '男') {
        if (ifBeforeMenseTime) {
          ifBeforeMenseTime = false
          this.userFormData.pop()
        }
        this.getQgInfos('男')
      } else {
        this.getQgInfos('女')
        if (!ifBeforeMenseTime) {
          ifBeforeMenseTime = true
          this.userFormData.push({
            name: 'BeforeMenseTime',
            label: '最近一次月经时间',
            placeholder: '请选择最近一次月经时间',
            rules: [{
              required: true,
              message: '请填写最近一次月经时间'
            }],
            type: 'time',
            value: ''
          })
        }
      }
      this.showPickerSex = false
    },
    // 时间选择
    onConfirmTime (values) {
      var dates = new Date()
      const nowdata = new Date(values)
      console.log(dates, nowdata, 'dateeeee')
      const year = nowdata.getFullYear()
      const month = nowdata.getMonth() + 1
      const date = nowdata.getDate()
      let clock = year + '-'
      if (month < 10) {
        clock += '0'
      };
      clock += month + '-'
      if (date < 10) {
        clock += '0'
      };
      clock += date

      const birthDay = this.userFormData.find(el => el.name === 'Birthday')
      this.getLastYearYestdy(dates)

      if (this.oldDate <= clock) {
        this.$toast.fail('不符合检测年龄')
      } else {
        birthDay.value = clock
      }

      this.form.age = new Date().getFullYear() - year
      this.showPicker = false
    },

    // 获取7年前
    getLastYearYestdy (date) {
      var strYear = date.getFullYear() - 7
      var strDay = date.getDate()
      var strMonth = date.getMonth() + 1
      if (strMonth < 10) {
        strMonth = '0' + strMonth
      }
      if (strDay < 10) {
        strDay = '0' + strDay
      }
      this.oldDate = strYear + '-' + strMonth + '-' + strDay
    },
    // 月经时间选择
    onConfirmYjTime (values) {
      const nowdata = new Date(values)
      const year = nowdata.getFullYear()
      const month = nowdata.getMonth() + 1
      const date = nowdata.getDate()
      let clock = year + '-'
      if (month < 10) {
        clock += '0'
      };
      clock += month + '-'
      if (date < 10) {
        clock += '0'
      };
      clock += date

      const BeforeMenseTime = this.userFormData.find(el => el.name === 'BeforeMenseTime')
      BeforeMenseTime.value = clock

      this.form.age = new Date().getFullYear() - year
      this.showYjPicker = false
    },

    // 预览
    seeFrom (item) {
      this.userFormData.forEach(item => {
        this.form[item.name] = item.value
      })
      if (!this.form.UserName) {
        this.$toast.fail('请填写姓名')
      } else if (!this.form.Sex) {
        this.$toast.fail('请选择性别')
      } else if (!this.form.Height) {
        this.$toast.fail('请填写身高')
      } else if (!this.form.Weight) {
        this.$toast.fail('请填写体重')
      } else if (!this.form.Birthday) {
        this.$toast.fail('请选择出生年月')
      } else if (!this.form.birthdayPlace) {
        this.$toast.fail('请选择出生地')
      } else if (!this.form.NowPlace) {
        this.$toast.fail('请选择现居地')
      } else if (!this.form.SlicingOrgan) {
        this.$toast.fail('请选择是否切除器官')
      } else if (this.form.Sex == '女' && !this.form.BeforeMenseTime) {
        this.$toast.fail('请选择最近一次月经时间')
      } else {
        this.onSeeform = false
      }
    },

    // 取消预览
    cancelFrom () {
      this.onSeeform = true
    },

    // 提交
    onSubmit (values) {
      this.userFormData.forEach(item => {
        this.form[item.name] = item.value
      })

      // console.log(this.form,'from123')
      this.form.code = this.code
      console.log(this.form.code,"this.form.code")
      this.updataUserInfo(this.form)
    },

    // 提交
    updataUserInfo (data) {
      console.log('提交信息', data)
      GetInsertReportUserA(data).then(res => {
        if (res.code == 0) {
          this.$toast.success('修改成功')
          this.ifYl = true
          wx.miniProgram.reLaunch({
                    url: '/pages/index/index'
                  })
          // this.$router.push('/personalCenter')
        }
      })
    }
  },
  watch: {

  }
}
</script>
<style lang='scss' scoped>
  .userInfo /deep/ .van-checkbox-group{
    width:70%;
    display: flex;
    justify-content: center;
  }
  .userInfo /deep/ .van-checkbox{
    display: flex;
    justify-content: space-between;
    width: 80%;
  }
  .userInfo /deep/ .unmit{
    margin-left:50rpx;
  }
  .van-cell__title {
    width: 10rem !important;
  }

  .userInfo {
    /deep/ .van-overlay {
      background-color: rgba(0, 0, 0, .2);
    }

    .submitBtn {
      padding: 0 40px;
      margin-top: 50px;

      .seebtns {
        display: flex;
        width: 200px;
        justify-content: center;
        margin: 0 auto;
      }

      .seeSumb {
        display: flex;
        justify-content: space-around;
      }
    }

    h6 {
      font-size: 14px;
      font-weight: 700;
      color: #000;
      margin: 40px 0 5px;
      padding: 0 15px;
    }

    .unmit {
      height: 240px;
      overflow-y: scroll;
      padding: 20px 0 0 20px;

      li .van-checkbox {
        padding-bottom: 20px;
      }
    }
  }
</style>
